import React, { useEffect } from "react";
import "./WelcomeScreen.css";
import WallaLogo from "../assets/wallalogo.png";
import Adidas from "../assets/adidaslogo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faFutbol } from "@fortawesome/free-solid-svg-icons";

interface WelcomeScreenProps {
  setPlaying: (playing: boolean) => void;
  setStarted: (playing: boolean) => void;
  poster: string | undefined;
}



const StartClick =
  "https://vidquiz.fra1.cdn.digitaloceanspaces.com/global/start.mp3";

var soundID = "StartClick";

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({
  setStarted,
  setPlaying,
  poster,
}) => {
  useEffect(() => {
    // @ts-ignore
    createjs.Sound.registerSound(StartClick, soundID);
  }, []);

  const handleStart = () => {
    // ref.current.play();
    //@ts-ignore
    createjs.Sound.play(soundID);

    setStarted(true);
    setPlaying(true);
  };

  const isMobile = window.innerWidth < window.innerHeight;
  return (
    <div
      // className="welcome-screen player-wrapper"
      onClick={handleStart}
      style={{ background: "transparent", zIndex: 100000 }}
    >
      <div className="welcome-overlay" />
      {poster && (
        <div className="poster" style={{ height: "100%", width: "100%" }}>
          <img
            style={{ height: "100%", borderRadius: "30px" }}
            width="100%"
            src={poster}
            alt="poster"
          ></img>
        </div>
      )}

      <div className="welcome-content">
        <div className="content">
          <button className="glow" onClick={handleStart}>
          <span className="fade-icon">
          <FontAwesomeIcon icon={faFutbol} spinPulse style={{color:"#000000", marginRight: "10px", backgroundColor: "white", borderRadius:"50%"}} />
            </span>
            שחקו עכשיו
         
          </button>
        </div>
      </div>
      
      
     
    </div>
  );
};

export default WelcomeScreen;
