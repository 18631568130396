import { useState } from "react";
import "./form.css";
var randomEmail = require("random-email");
const LIST_ID = 17903;

const Form = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // Logic to handle form submission
    console.log("Form submitted:", name, phone);
    // Flashy global vriable ready to be used.
    const [first, last, secondlast] = name.split(" ");

    //@ts-ignore
    // // @ts-ignore
    // console.log(flashy);

    // Reset the form fields
    setName("");
    setPhone("");
    setSubmitted(true);
  };

  return (
    <>
      {" "}
      {!submitted && (
        <form
          className="flashy-form"
          style={{ lineHeight: "40px" }}
          onSubmit={handleSubmit}
        >
          <div>
            <input
              placeholder="⁠שם מלא"
              type="text"
              id="name"
              value={name}
              required={true}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <input
              placeholder="טלפון"
              type="tel"
              id="phone"
              required={true}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div style={{ direction: "rtl" }}>
            {" "}
            <label>מאשר/ת קבלת דיוור</label>
            <input style={{ width: 10 }} required={true} type="checkBox" />
          </div>

          <button
            style={{
              backgroundImage: "linear-gradient(to right, #02d6f4, #038cbe)",
              color: "black",
              marginTop: "10px",
              borderRadius: "50px",
              fontSize: "18px",
            }}
            type="submit"
          >
            לשליחה
          </button>
        </form>
      )}
      {submitted && (
        <div className="message">
          תודה<br></br> הפרטים נרשמו
        </div>
      )}
    </>
  );
};

export default Form;
